
// icons
import { person, } from 'ionicons/icons';

// components
import { IonBadge, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
        IonAvatar, IonChip, IonLabel, IonIcon, IonButtons, IonButton, IonImg,
        modalController, } from "@ionic/vue";
import ImageSlides from "@/components/ImageSlides.vue";

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useRouter } from 'vue-router';

export default {
  props: [
    "project",
    "extraRouterParams",
    "hideDetails",
    "disableRouterLink",
    "userLocation",
    "hideStatus",
  ],
  components: {
    IonBadge, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
    IonAvatar, IonChip, IonLabel, IonIcon, IonButtons, IonButton, IonImg,
    ImageSlides,
  },
  setup(props) {
    const { t } = useI18n();
    const { formatDate, getProjectStatusColor, numberWithCommas, getProjectDisplayProgress, getLocalizedStr } = utils();
    const router = useRouter();

    return {
      // icons
      person,

      // methods
      t, getLocalizedStr,
      formatDate, getProjectStatusColor, numberWithCommas,
      getProjectDisplayProgress,
      goToProjectDetailPage: () => {
        modalController.dismiss();
        router.push({ name: 'ProjectDetailPage', params: { id: props.project.id } }); // go to corresponding project page
      },
    };
  },
};
