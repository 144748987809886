
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { add, close, search, scan, qrCode, qrCodeOutline, navigate, arrowBack, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonSearchbar, IonToolbar, IonTitle, IonContent, IonRow, IonCol,
        IonGrid, IonList, IonItem, IonLabel, IonIcon, IonThumbnail, IonButtons, IonButton,
        IonSkeletonText, IonSegment, IonSegmentButton, IonChip, IonBackButton, } from '@ionic/vue';
import ProjectCard from "@/components/ProjectCard.vue";

// composables
import config from '@/config';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { utils } from '@/composables/utils';
import { useQRCodeScanner } from '@/composables/useQRCodeScanner';

// Google Map
import { GoogleMap, Marker, Circle, } from 'vue3-google-map';
import { Geolocation } from '@capacitor/geolocation';

export default {
  name: 'ProjectListPage',
  components: { IonPage, IonHeader, IonSearchbar, IonToolbar, IonTitle, IonContent, IonRow, IonCol,
                IonGrid, IonList, IonItem, IonLabel, IonIcon, IonThumbnail, IonButtons, IonButton,
                IonSkeletonText, IonSegment, IonSegmentButton, IonChip, IonBackButton,
                ProjectCard,
                GoogleMap, Marker, Circle, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { PROJECT_STATUSES, focusKeywordSearchbar, openModal, } = utils();
    const router = useRouter();
    const { scanningQRCode, startScanQRCode, stopScan, } = useQRCodeScanner();

    // 1. declare state variables (ref to make them reactive)
    const currUser = computed(() => store.state.user);
    const loading = computed(() => store.state.loadingProjects);
    const allProjects = computed(() => store.getters.sortedProjects);
    const allProjectTypes = computed(() => store.getters.allProjectTypes);

    const showSearchBar = ref(false);
    const searchKeyword = ref("");

    const inMapView = ref(false);
    const statusFilter = ref("all");
    const selectedProjectType = ref("all");
    const currLat = ref(22.4468101);
    const currLng = ref(114.1656347);
    const googleMapsApiKey: any = config.googleMapsApiKey;

    // methods or filters
    const getCurrentPosition = async() => {
      inMapView.value = true;
      const coordinates = await Geolocation.getCurrentPosition();
      currLat.value = coordinates.coords.latitude;
      currLng.value = coordinates.coords.longitude;
    }
    const onClickMapMarker = (project: any) => {
      router.push({ name: 'ProjectDetailPage', params: { id: project.id } }); // go to corresponding project page
    }

    const setSelectedProjectType = (type = 'all') => {
      selectedProjectType.value = type;
    }
    const filteredProjects = () => {
      const res = allProjects.value.filter((p: any) => {
        return p.title.toLowerCase().includes(searchKeyword.value.toLowerCase()) && (statusFilter.value == 'all' || statusFilter.value == p.status) &&
                                  (selectedProjectType.value == 'all' || p.type?.includes(selectedProjectType.value))
      });
      if (!res.find(p => p.id == 'demo')) {
        res.push({"id":"demo","title":"Demo Building","locationId":null,"type":null,"amount":9999999,"startDate":"2024-05-20","endDate":"2030-05-20","status":"工程中","address":"1 Demo Street, Hong Kong","customerId":"demo","progress":0.004633188723439322,"remainingDays":2180,"userRoles":[{"id":"site-manager","title":"地盤管理員","titleEn":"Site Manager","permissions":"canAccessOLPage , canEditOLPage , canExportOLReports , canMovePoints , canAddNewPoints , canManageProjectUsers , canManageWorkLocations"}],"userPermissions":["canAccessOLPage","canEditOLPage","canExportOLReports","canMovePoints","canAddNewPoints","canManageProjectUsers","canManageWorkLocations"]})
      }
      return res;
    }
    const startScanProjectQRCode = async () => {
      const projectId: any = await startScanQRCode();
      if (projectId != null) {
        router.push({ name: 'ProjectDetailPage', params: { id: projectId, src: "qrcode" } }); // go to corresponding project page
      }
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, search, scan, qrCode, qrCodeOutline, navigate, arrowBack,

      // variables
      loading, currUser, allProjects, allProjectTypes,
      statusFilter, PROJECT_STATUSES,
      selectedProjectType,
      scanningQRCode, showSearchBar, searchKeyword,
      currLat, currLng, googleMapsApiKey, inMapView,

      // methods
      t, startScanProjectQRCode, stopScan, getCurrentPosition, onClickMapMarker,
      filteredProjects,
      focusKeywordSearchbar,
      setSelectedProjectType,
    }
  }
}
